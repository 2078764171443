import { useRef, useState, useEffect } from 'react';
import Avatar from '../Avatar';
import ManualToast from '../ManualToast';

export default ({
	value,
	name,
	errors = [],
	onChange,
	display
}) => {
	const [image, setImage] = useState();
	const [preview, setPreview] = useState(value);
	const fileInputRef = useRef();
	const [toast, setToast] = useState(null);

	const handleFileSelect = (event) => {
		event.preventDefault();
		fileInputRef.current.click();
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.type.substr(0, 5) === 'image') {
			// Conversion to KB
			const fileSize = Math.round(file.size / 1024);
			const fileLimit = 4096; // 4MB

			if (fileSize > fileLimit) {
				setToast({
					message:
						'File too large, max size is 4MB.',
					type: 'alert-error'
				});
				return;
			}

			setImage(file);
		} else {
			setImage(null);
		}

		onChange(file);
	};

	useEffect(() => {
		if (image) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(image);
		}
	}, [image]);

	return (
		<>
			{preview ? (
				<>
					{display === 'avatar' ? (
						<div>
							<Avatar
								url={preview}
								onClick={handleFileSelect}
								style={{
									cursor: 'pointer'
								}}
							/>
						</div>
					) : (
						<div>
							<img
								src={preview}
								onClick={handleFileSelect}
								alt="preview"
								style={{
									objectFit: 'cover',
									cursor: 'pointer'
								}}
							/>
						</div>
					)}
				</>
			) : (
				<button onClick={handleFileSelect}>
					Add Image
				</button>
			)}

			<input
				type="file"
				accept="image/*"
				style={{ display: 'none' }}
				ref={fileInputRef}
				onChange={handleFileChange}
			/>

			<ManualToast toast={toast} />
		</>
	);
};
